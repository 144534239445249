import {createSlice} from "@reduxjs/toolkit";


let userState = { 'jwt': null };
if (localStorage) {
    const stateJson = localStorage.getItem('state.user');
    if (stateJson) {
        userState = JSON.parse(stateJson);
    }
}

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: userState,
    reducers: {
        setUser: (state, action) => {
            state['jwt'] = action.payload;
            localStorage.setItem('state.user', JSON.stringify(state));
        }
    }
});

export default userSlice.reducer;

export const {setUser} = userSlice.actions;
