import './App.css';
import Layout from "./components/Layout/Layout.lazy";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./components/Login/Login.lazy";
import React from "react";
import 'react-bootstrap/'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Dashboard from "./components/Dashboard/Dashboard.lazy";
import Report from "./components/Report/Report.lazy";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route element={<Layout/>} path="/">
                  <Route element={<Login/>} path="/login"/>
                  <Route element={<Report/>} path="/report"/>
                  <Route element={<Dashboard/>} path="/"/>
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
